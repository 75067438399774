
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
const video = ref();
const canvas = ref();
const captures: any = ref([]);
const isCameraOpen = ref(false);
const listData = ref();
const limit = ref(6);
const page = ref(0);
const orderBy = ref(1);
const sortBy = ref(3);
const categories = ref([]);
const totalPage = ref(0);
const filterForm = ref({
  category: "",
  status: "",
  orderBy: "",
});
export default defineComponent({
  name: "voluntary",
  components: {},
  data() {
    return {
      categoryList: [],
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("ข่าวสารและบทความ", []);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      // console.log("getDataList");
     
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getNews?sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            sortBy.value,
          {
            headers: {
              token: localStorage.getItem("id_token") ? localStorage.getItem("id_token") : localStorage.getItem("tokenGuest")
            }
          }
        )
        .then((response) => {
          // console.log(response.data.data);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      sortBy,
      categories,
      totalPage,
      filterForm,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    removeStyle(html) {
      const regex = /(style=".+?")/gm;
      const str = html;
      const subst = ``;
      const result = str.replace(regex, subst);
      return result;
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
